import React, { useEffect, useContext, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'

import { useVehicleStore } from "Clutch/Stores/VehicleStore"
import { NavigationContext } from 'Clutch/Contexts/Navigation/NavigationContext'
import { ProductPageContext } from '../../../contexts/ProductPageContext'

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'

import Fits from './fitments/fits'
import DoesntFit from './fitments/doesntFit'
import SelectVehicle from './fitments/selectVehicle'

import { determineFitmentDisplayType, fitmentDisplayTypes } from './determineFitmentType'
import SelectFitment from './fitments/SelectFitment/SelectFitment'
import MightFit from './fitments/mightfit/mightFit'
import { createCheckFitmentRequest } from '../../../utils/fitment/checkFitmentUtils'

const VehicleFitment = ({checkFitment, breadcrumb, showFitmentDrawer, fitment}) => {

  const customerVehicles = useVehicleStore(x => x.context.customerVehicles)
  const productPageContext = useContext(ProductPageContext);
  const selectedVehicleState = useVehicleStore(x => x.context.selectedVehicle)
  const navigationContext = useContext(NavigationContext)
  const fitmentType = productPageContext.fitmentType;

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '200px 0px',
  });

  useEffect(() => {
    if (inView && customerVehicles && customerVehicles.length) {
      const checkFitmentRequest = createCheckFitmentRequest(customerVehicles);
      checkFitment(checkFitmentRequest)
    }
  }, [inView, customerVehicles])

  const vehicleBaseProjectIds = customerVehicles?.filter(customerVehicle => customerVehicle.vehicleBaseId).map(customerVehicle => customerVehicle.projectId);
  const raceTypeProjectIds = customerVehicles?.filter(customerVehicle => customerVehicle.raceTypeId).map(customerVehicle => customerVehicle.projectId);
  const engineProjectIds = customerVehicles?.filter(customerVehicle => customerVehicle.engineDisplayName && !customerVehicle.raceTypeId && !customerVehicle.vehicleBaseId).map(customerVehicle => customerVehicle.projectId);

  let vehicleBaseFitment = []
  let raceTypeFitment = []
  let engineFitment = []

  if (fitment && fitment.length) {
    vehicleBaseFitment =  fitment.filter(f => vehicleBaseProjectIds.includes(f.key))
    raceTypeFitment =  fitment.filter(f => raceTypeProjectIds.includes(f.key))
    engineFitment =  fitment.filter(f => engineProjectIds.includes(f.key))

  }
  const fitmentDisplayType =  useMemo(() => determineFitmentDisplayType(fitmentType, customerVehicles, selectedVehicleState, vehicleBaseFitment, raceTypeFitment, engineFitment), [fitmentType, customerVehicles, selectedVehicleState, vehicleBaseFitment, raceTypeFitment, engineFitment])

  const vehicleBasesThatFit = vehicleBaseFitment && vehicleBaseFitment.length ? 
    vehicleBaseFitment.filter(vehicle => vehicle && vehicle.value.fits).map(vehicle => {return{'key': vehicle.key, ...vehicle.value}}) : []
  const raceTypesThatFit = raceTypeFitment && raceTypeFitment.length ? 
    raceTypeFitment.filter(vehicle => vehicle && vehicle.value.fits).map(vehicle => {return{'key': vehicle.key, ...vehicle.value}}) : []
  const enginesThatFit =  engineFitment && engineFitment.length ? 
    engineFitment.filter(vehicle => vehicle && vehicle.value.fits).map(vehicle => {return{'key': vehicle.key, ...vehicle.value}}) : []
  const selectedVehicleBases = customerVehicles ? customerVehicles.filter(vehicle => vehicle && vehicle.vehicleBaseId === selectedVehicleState?.vehicleBaseId) : []
  const selectedRaceTypes = customerVehicles ? customerVehicles.filter(vehicle => vehicle && vehicle.raceTypeId === selectedVehicleState?.raceTypeId) : []
  const selectedEngines = customerVehicles ? customerVehicles.filter(vehicle => vehicle && vehicle.engineDisplayName && !vehicle.vehicleBaseId && !vehicle.raceTypeId && vehicle.engineDisplayName === selectedVehicleState?.engineDisplayName) : []
  
  const selectedVehicle = [].concat((selectedVehicleBases || [])).concat((selectedRaceTypes || [])).concat((selectedEngines || []))[0]

  const matchingVehicleBaseFitment = vehicleBaseFitment ? 
    vehicleBaseFitment.filter(baseFitment => baseFitment.key === selectedVehicle?.vehicleBaseId) : []
  const shopUrl = matchingVehicleBaseFitment && matchingVehicleBaseFitment.length ? 
    matchingVehicleBaseFitment[0].value.shopUrl : 
    selectedVehicle ? selectedVehicle.shopUrl : ""
  const selectedVehicleFitment = fitment?.find(fitment => fitment.key === selectedVehicle.projectId);

  let FitmentDisplayComponent = null;
  if (fitmentDisplayType === fitmentDisplayTypes.selectVehicle ||
      fitmentDisplayType === fitmentDisplayTypes.selectEngine ||
      fitmentDisplayType === fitmentDisplayTypes.selectVehicleAndEngine) {
    FitmentDisplayComponent = (
      <SelectFitment type={fitmentDisplayType}/>
    );
  } else if (
    fitmentDisplayType === fitmentDisplayTypes.fits || 
    fitmentDisplayType === fitmentDisplayTypes.direct ||
    fitmentDisplayType === fitmentDisplayTypes.universal
  ) {
    FitmentDisplayComponent = (
      <Fits
        selectedVehicle={selectedVehicle}
        vehicleBasesThatFit={vehicleBasesThatFit}
        raceTypesThatFit={raceTypesThatFit}
        enginesThatFit={enginesThatFit}
        customerVehicles={customerVehicles}
        showFitmentDrawer={showFitmentDrawer}
        fitmentType={fitmentDisplayType}
      />
    );
  } else if (fitmentDisplayType === fitmentDisplayTypes.needsMoreInfo) {
    FitmentDisplayComponent = (
      <MightFit
        selectedVehicle={selectedVehicle}
        customerVehicles={customerVehicles}
        promptUserFor={selectedVehicleFitment?.value?.promptUserFor}
      />
    );
  } else if (fitmentDisplayType === fitmentDisplayTypes.doesntFit) {
    FitmentDisplayComponent = (
      <DoesntFit
        selectedVehicle={selectedVehicle}
        vehicleBasesThatFit={vehicleBasesThatFit}
        raceTypesThatFit={raceTypesThatFit}
        enginesThatFit={enginesThatFit}
        breadcrumb={breadcrumb}
        partialFit={selectedVehicleFitment?.value?.partialFit}
        showFitmentDrawer={showFitmentDrawer}
        customerVehicles={customerVehicles}
        shopUrl={shopUrl}

      />
    );
  }
  
  return (
    <ErrorBoundary>
      {customerVehicles && customerVehicles.length ? <div ref={ref}></div> : false}
      {FitmentDisplayComponent}
    </ErrorBoundary>
  )
}

export default VehicleFitment