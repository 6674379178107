import { useEffect, useState, useContext, useRef } from "react";
import { ProductPageContext } from "./../../contexts/ProductPageContext";
import useFeatureFlag from "Clutch/Hooks/useFeatureFlag";
import Modal from "Clutch/Components/Modal/ModalWithPortal";
import { EngineFitmentFeedbackModalButton } from "./engineFitmentFeedback";

const EngineFitmentFeedbackWrapper = () => {
  try {
    const productPageContext = useContext(ProductPageContext);
    const feedbackEnabled = useFeatureFlag(
      "PDP_Show_Fitment_Feedback_Internals"
    );
    const [showFeedback, setShowFeedback] = useState(false);
    const modal = useRef(null);

    useEffect(() => {
      if (showFeedback) modal?.current?.open();
      if (!showFeedback) modal?.current?.close();
    }, [showFeedback]);

    let EngineFitmentFeedbackModalContents;
    if (!!productPageContext.engineFitmentForSku && !!feedbackEnabled) {
      EngineFitmentFeedbackModalContents =
        require("./engineFitmentFeedback").default;
    }

    return (
      <>
        <EngineFitmentFeedbackModalButton
          showFeedback={showFeedback}
          setShowFeedback={setShowFeedback}
          shouldRender={(!!productPageContext.engineFitmentForSku && !!feedbackEnabled)}
        />
        <Modal
          id={"engineFitmentFeedbackModal"}
          outsideClick
          ref={modal}
          customOnClose={() => setShowFeedback(false)}
        >
          {EngineFitmentFeedbackModalContents ? (
            <EngineFitmentFeedbackModalContents
              skuNumber={productPageContext.skuVariantNumber}
              engineFitmentData={productPageContext.engineFitmentForSku}
            />
          ) : (
            false
          )}
        </Modal>
      </>
    );
  } catch (err) {
    console.error(err);
    return false;
  }
};

export default EngineFitmentFeedbackWrapper;
