import React, { useState, useEffect, useContext } from 'react'
import Image from 'next/image'

import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import { TrackingContext } from 'Clutch/Contexts/TrackingContext/TrackingContext'
import { ProductPageContext } from '../../contexts/ProductPageContext'
import {getSuperMarketAffinity} from 'Clutch/Utilities/Instrumentation/SegmentUtilities/segmentFunctions'

import Drawer from 'Clutch/Molecules/Drawer'
import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Divider from 'Clutch/Atoms/Divider'

import groupBy from '../../utils/groupBy'

import styles from './garageSaleDrawer.module.scss'

const GarageSaleDrawer = ({ }) => {

  const productPageContext = useContext(ProductPageContext)
  const userGuid = useUserStore((x) => x.context.userGuid);
  const userVin = useUserStore((x) => x.context.userVin);

  const { segmentReady } = useContext(TrackingContext)

  const [segmentEventFired, setSegmentEventFired] = useState(false)

  const title = 'Garage Sale Alternatives'

  useEffect(() => {
    if(productPageContext.isGarageSaleDrawerOpen && !segmentEventFired) {
      //send segment product list viewed
      //this feeds miso recommendation impressions
      if (segmentReady && (productPageContext.garageSaleSkuVariants || []).length > 0) {
        try{
          analytics.track('Product List Viewed', {
            'user_vin': `${userVin()}`,
            'web_user_guid': `${userGuid}`,
            'list_id': `pdp_drawer_garageSale`,
            'category': `${title}`,
            'products': (productPageContext.garageSaleSkuVariants || []).map((garageSaleSku, i) => {
              return {
                'product_id': garageSaleSku.skuBaseNumber,
                'sku': garageSaleSku.skuVariantNumber,
                'name': garageSaleSku.displayTitle,
                'price': garageSaleSku.price,
                'position': i,
                'category': productPageContext.segmentCategorization,
                'url': garageSaleSku.productPageUrl,
                'image_url': garageSaleSku.imageUrl,
                'quantity': 1,
                'brand': productPageContext.brand ? productPageContext.brand.brandName || '' : '',
                'availability': garageSaleSku.availability,
                'rating': garageSaleSku.reviewCount ? garageSaleSku.rating : 0,
                'reviews': garageSaleSku.reviewCount,
                'audience_affinity': getSuperMarketAffinity(garageSaleSku)
              }
            })
          })
        }
        catch(err){
          console.error(err)
        }

        setSegmentEventFired(true)
      } else {
        console.info('analytics not defined')
      }
    }
  }, [productPageContext.isGarageSaleDrawerOpen, segmentReady])




  const groupedGarageSaleSkuVaraints = groupBy((productPageContext.garageSaleSkuVariants || []), 'garageSaleType')

  return (
    <Drawer
      location={'right'}
      width={640}
      open={!!productPageContext.isGarageSaleDrawerOpen}
      closeFunction={() => productPageContext.hideGarageSaleDrawer()}>

      <div className={styles.garage_sale_container}>
        <Typography size={1.25} font={'heading'}>
          {title}
        </Typography>
        <Divider className={styles.divider} />

        {Object.values(groupedGarageSaleSkuVaraints).map((garageSaleSkuVariantGroup, i) =>
          <ErrorBoundary>

            <Typography size={0.875} font={'bold'} className={styles.garage_sale_type}>
              {(garageSaleSkuVariantGroup || []).length && garageSaleSkuVariantGroup[0].garageSaleType ? `${garageSaleSkuVariantGroup[0].garageSaleType}:` : 'Other:'}
            </Typography>

            {(garageSaleSkuVariantGroup || []).map((garageSaleSkuVariant, j) =>
              <ErrorBoundary>
                <div className={styles.rec_wrapper}>
                  {/* Left Side */}
                  <span className={styles.rec_image_wrapper}>
                    <TextLink href={garageSaleSkuVariant.productPageUrl}>
                      <Image
                        height={64}
                        width={64}
                        src={garageSaleSkuVariant.imageUrl ?
                          `https:${garageSaleSkuVariant.imageUrl}` :
                          'https://content.speedwaymotors.com/OtherImages/missingimage2.jpg'} />
                    </TextLink>
                  </span>
                  {/* Right Side */}
                  <span>
                    <TextLink href={garageSaleSkuVariant.productPageUrl} size={0.875}>
                      {garageSaleSkuVariant.displayTitle}
                    </TextLink>
                    <Typography size={1} font={'bold'}>
                      {garageSaleSkuVariant.priceString}
                    </Typography>
                  </span>
                </div>
                {j !== productPageContext.garageSaleSkuVariants.length - 1 ? <Divider className={styles.divider} /> : false}
              </ErrorBoundary>
            )}

          </ErrorBoundary>
        )}


      </div>
    </Drawer>
  )
}

export default GarageSaleDrawer