import React, { useState, useContext, useEffect } from 'react'
import Image from 'next/image'


import { ProductPageContext } from '../../contexts/ProductPageContext'

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import Divider from 'Clutch/Atoms/Divider'
import Drawer from 'Clutch/Molecules/Drawer'
import Area from 'Clutch/Molecules/Area'

import styles from './whatsInTheKitDrawer.module.scss'

const WhatsInTheKitDrawer = ({ }) => {

  const productPageContext = useContext(ProductPageContext)
  const [kitComponents, updateKitComponents] = useState([])


  useEffect(() => {
    const clonedKitComponents = JSON.parse(JSON.stringify(productPageContext.kitComponents || []));
    let skuBaseKitComponents = [];

    //de dup sku bases but combine quantities
    (clonedKitComponents || []).forEach(x =>
      skuBaseKitComponents.filter(y => y.skuBaseNumber === x.skuBaseNumber)[0] ?
        skuBaseKitComponents.filter(y => y.skuBaseNumber === x.skuBaseNumber)[0].purchaseQuantity += x.purchaseQuantity :
        skuBaseKitComponents.push(x))

    const skuVariantKitComponents = productPageContext.kitVariantComponents || []

    const kitComponents = skuBaseKitComponents.concat(skuVariantKitComponents)
      .sort((a, b) => {
        return parseInt(a.ordinality) - parseInt(b.ordinality);
      })

    updateKitComponents(kitComponents)
  }, [productPageContext.kitComponents, productPageContext.kitVariantComponents])
  
  return (
    <ErrorBoundary>
      <Drawer
        location={'right'}
        width={640}
        open={productPageContext.isWhatsInTheKitDrawerOpen}
        closeFunction={() => productPageContext.hideWhatsInTheKitDrawer()}>
        <div className={styles.kit_components_container}>
          <Typography size={1.25} font={'heading'}>
            Kit Components
          </Typography>
          {kitComponents.map((kitComponent, i) =>
              <ErrorBoundary key={`kit_component_${kitComponent.skuVariantNumber ? kitComponent.skuVariantNumber : kitComponent.skuBaseNumber}_${i}`}>
                <Divider className={styles.component_divider} />
                <div data-testid={`kit_component_${kitComponent.skuVariantNumber ? kitComponent.skuVariantNumber : kitComponent.skuBaseNumber}_${i}`} className={styles.kit_component}>
                  <div className={styles.component_image}>
                    {kitComponent.productPageUrl ?
                      <TextLink targetBlank href={kitComponent.productPageUrl}>
                        <Image
                          height={64}
                          width={64}
                          src={kitComponent.imageUrl ? `https:${kitComponent.imageUrl}` : 'https://content.speedwaymotors.com/OtherImages/missingimage2.jpg'}
                          data-testid={`kit_component_image_link_${kitComponent.skuVariantNumber ? kitComponent.skuVariantNumber : kitComponent.skuBaseNumber}_${i}`} />
                      </TextLink>
                      :
                      <Image
                        height={64}
                        width={64}
                        src={`https:${kitComponent.imageUrl}`}
                        data-testid={`kit_component_image_${kitComponent.skuVariantNumber ? kitComponent.skuVariantNumber : kitComponent.skuBaseNumber}_${i}`} />
                    }
                  </div>
                  <div className={styles.component_right_side}>
                    <Typography size={0.625}>{`Part #: ${kitComponent.skuVariantNumber ? kitComponent.skuVariantNumber : kitComponent.skuBaseNumber}`}</Typography>
                    {kitComponent.productPageUrl ?
                      <TextLink
                        targetBlank
                        size={1}
                        className={styles.component_name}
                        href={kitComponent.productPageUrl}
                        data-testid={`kit_component_textlink_${kitComponent.skuVariantNumber ? kitComponent.skuVariantNumber : kitComponent.skuBaseNumber}_${i}`}>
                        {kitComponent.skuVariantName ? kitComponent.skuVariantName : kitComponent.skuBaseName}
                      </TextLink>
                      :
                      <Typography
                        targetBlank
                        size={1}
                        className={styles.component_name}
                        href={kitComponent.productPageUrl}
                        data-testid={`kit_component_typography_${kitComponent.skuVariantNumber ? kitComponent.skuVariantNumber : kitComponent.skuBaseNumber}_${i}`}>
                        {kitComponent.skuVariantName ? kitComponent.skuVariantName : kitComponent.skuBaseName}
                      </Typography>
                    }
                    <Typography size={0.875} font={'heading'}>{`Qty: ${kitComponent.purchaseQuantity}`}</Typography>                 
                  </div>
                </div>
                {
                  kitComponent.availability == 'NotAvailable' ? <Area intent={'negative'} className={styles.component_divider_bottom}><Typography size={0.875} font={'bold'}>{`This part is currently Out of Stock.`}</Typography></Area> :
                  kitComponent.availability == 'Discontinued' ? <Area intent={'negative'} className={styles.component_divider_bottom}><Typography size={0.875} font={'bold'}>{`This part is Discontinued.`}</Typography></Area> :
                  kitComponent.availability == 'Backorderable' ? <Area intent={'warning'} className={styles.component_divider_bottom}><Typography size={0.875} font={'bold'}>{`This part is Backordered.`}</Typography></Area> :
                  kitComponent.availability == 'FactoryShipped' ? <Area intent={'warning'} className={styles.component_divider_bottom}><Typography size={0.875} font={'bold'}>{`This part is Factory Shipped.`}</Typography></Area> :
                  kitComponent.availability == 'MadeToOrder' ? <Area intent={'warning'} className={styles.component_divider_bottom}><Typography size={0.875} font={'bold'}>{`This part is Made to Order.`}</Typography></Area> :
                  false
                }
              </ErrorBoundary>
            )}
          <Divider className={styles.component_divider_bottom} />
        </div>
      </Drawer >
    </ErrorBoundary>
  )
}

export default WhatsInTheKitDrawer