import Typography from "Clutch/Atoms/Typography/Typography";
import Button from "Clutch/Molecules/Button/Button";
import EngineFitmentRow from "./engineFitmentFeedbackRow"

import styles from "./engineFitmentFeedback.module.scss";

export const EngineFitmentFeedbackModalButton = ({
  showFeedback,
  setShowFeedback,
  shouldRender,
}) => {
  if (!shouldRender) return false;
  return (
    <Button
      className={styles.feedbackButton}
      brand={"primary"}
      size={"small"}
      onClick={() => setShowFeedback(!showFeedback)}
      segmentEvent={{ event: "engineFitmentFeedbackButton" }}
    >
      <Typography size={1}>Engine Fitment Feedback</Typography>
    </Button>
  );
};

const EngineFitmentFeedbackModalContents = ({
  skuNumber,
  engineFitmentData,
}) => {
  if (!engineFitmentData) return false;

  return (
    <div className={styles.feedbackWrapper}>
      <Typography
        font={"bold"}
        as={"h2"}
        brand={"primary"}
        style={{ marginBottom: "1rem" }}
      >
        Engine Fitment Feedback
      </Typography>
      {(engineFitmentData || []).map((engine) => {
        return (
          <EngineFitmentRow
            skuNumber={skuNumber}
            engine={engine}
            key={`fitment-row-${engine.engineId}`}
          />
        );
      })}
    </div>
  );
};

export default EngineFitmentFeedbackModalContents;
