import React, { useState } from 'react'
import ClassNames from 'classnames'

import TextLink from 'Clutch/Atoms/TextLink'

import ImageBlurrable from './ImageBlurrable'

import styles from './gallery.module.scss'

const GalleryImage = ({ src, index, onMouseMove, onClick, isDesktop, spinImages, position, zoomed, grabbed, alt, onDragStart, onDragEnd, onDrag }) => {


  const [selectedSpinImage, setSelectedSpinImage] = useState(0)

  if((spinImages || []).length){
    src = spinImages[selectedSpinImage]
    zoomed = false
  }

  let className = ""
  if (isDesktop && !(spinImages || []).length) {
    className = index === position && zoomed ? styles.image_main_desktop_zoomed : styles.image_main_desktop_zoomable
  }

  // if (isDesktop && (spinImages || []).length) {
  //   className = index === position && grabbed ? styles.spin_image_main_desktop_grabbed : styles.spin_image_main_desktop
  // }

  return <>
    {(spinImages || []).length ?
      <div className={styles.spin_image_wrapper}>
        {spinImages.map((spinImage, i) =>
          <div className={i == selectedSpinImage ? null : styles.hidden_spin_image}>
            <ImageBlurrable
              layout={'fill'}
              src={spinImage}
              sizes='(min-width: 768px) 44vw,(min-width: 1336px) 60vw, 95vw'
              width={567}
              height={567}
              alt={alt}
              lazyBoundary={'200px'}
              onMouseMove={onMouseMove}
              onClick={onClick}
              onDrag={onDrag}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              className={ClassNames(className, i == selectedSpinImage ? styles.selected_spin_image : styles.deselected_spin_image)}
            />
          </div>
        )}
        <input
          className={styles.spin_range}
          type={'range'}
          min={0}
          max={spinImages.length - 1}
          value={selectedSpinImage}
          onChange={(e) => { setSelectedSpinImage(e.target.value) }}>
        </input>
      </div> :
      <ImageBlurrable
        src={src}
        sizes='(min-width: 768px) 44vw,(min-width: 1336px) 60vw, 95vw'
        width={567}
        height={567}
        alt={alt}
        priority={index === 0}
        lazyBoundary={index === 1 ? '-15px' : '200px'}
        onMouseMove={onMouseMove}
        onClick={onClick}
        className={className}
      />
    }
  </>
}


export default GalleryImage