
import React, { Fragment, useContext, useEffect, useState } from 'react'

import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import Area from 'Clutch/Molecules/Area'
import Button from 'Clutch/Molecules/Button'
import Form from 'Clutch/Atoms/Form'
import Svg from 'Clutch/Atoms/Svg'
import { useLazyFeatureFlag } from 'Clutch/Hooks/useFeatureFlag/useFeatureFlag';

import FeatureFlagWrapper from 'Clutch/UtilityComponents/FeatureFlagWrapper'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Share from 'Molecules/Share/'

import { GetUserGuid } from 'Clutch/Utilities/CookieUtilties/UserCookieUtilities'
import { getSuperMarketAffinity } from "Utilities/Instrumentation/SegmentUtilities/segmentFunctions";

import styles from './atcForm.module.scss'
import styled from 'styled-components'
import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

const AddToCartForm = ({ isIndexOptionKit, skuData, quantityRequested, updateQuantityRequested, errorIndexOptions, loadingIndexOptionSelection, showKitDetailsDrawer, showListDrawer, addToCart, isWaiting, setIsWaiting, lastPurchaseDateForSku, powerReviewsMerchantId, powerReviewsMerchantGroupId, freeShippingThreshold }) => {


  const callToOrderEnlargedFF = useLazyFeatureFlag("Call_To_Order_Enlarged", typeof (window) != 'undefined' && window.innerWidth < 768)




  const item = {
    skuVariantNumber: skuData.skuVariant,
    skuBaseNumber: skuData.skuBase,
    quantityRequested: parseInt(skuData.quantityRequested),
    title: skuData.title,
    images: skuData.images,
    segmentCategory: skuData.segmentCategory,
    brand: skuData.brand,
    price: skuData.price,
    url: skuData.productPageUrl,
    superMarketAffinity: skuData.superMarketAffinity,
    availability: skuData.availabilityEnum,
    rating: skuData.rating,
    reviewCount: skuData.reviewCount
  }

  const callToOrderEvent = {
    action: 'Call to Order',
    label: 'Product Details Page Offer Section'
  }

  const addToCartWrapper = (items) => {
    setIsWaiting(true)
    addToCart(items).then(() => {
      setIsWaiting(false)
    })
  }

  return (
    <ErrorBoundary>
      <Fragment>
        <Form id={'pdp-add-to-cart-form'} onSubmit={() => { }}>
          <div className={styles.pdp_offer_qty_wrapper}>
            <Typography className={styles.pdp_offer_qty_text} size={1} font={'bold'} inline>
              Quantity
            </Typography>
            <Form.Quantity
              id={styles.pdp_offer_qty}
              defaultValue={quantityRequested}
              minValue={1}
              maxValue={999}
              onChange={(q) => { updateQuantityRequested(q) }}
            />
          </div>
          <Button
            className={styles.pdp_atc_btn}
            intent={'positive'}
            disabled={loadingIndexOptionSelection}
            size={'large'}
            fill
            segmentEvent={{
              event: 'Product Add Started',
              properties: {
                cart_id: GetUserGuid(),
                product_id: item.skuBaseNumber,
                sku: item.skuBaseNumber,
                name: item.title,
                variant: item.skuVariantNumber,
                quantity: item.quantityRequested,
                brand: item.brand,
                category: item.segmentCategory,
                price: item.price,
                url: item.url,
                image_url: (item.images || []).length ? `https:${item.images[0]}` : "",
                availability: item.availability,
                rating: item.reviewCount ? item.rating : 0,
                reviews: item.reviewCount,
                audience_affinity: getSuperMarketAffinity(item),
              }
            }}
            text={'Add to Cart'}
            isWaiting={isWaiting}
            onClick={(e) => {
              e.preventDefault()
              isIndexOptionKit
                ? showKitDetailsDrawer({ atc: true })
                : !skuData.skuVariant
                  ? errorIndexOptions()
                  : addToCartWrapper([item])
            }}
            data-testid={'pdp-add-to-cart'} />
          <Button
            className={callToOrderEnlargedFF ? styles.pdp_cto_btn : styles.pdp_small_cto_btn}
            intent={'subdued'}
            brand={callToOrderEnlargedFF ? 'secondary' : ''}
            icon={'phone'}
            disabled={loadingIndexOptionSelection}
            size={callToOrderEnlargedFF ? 'medium' : 'small'}
            fill
            segmentEvent={createClickedSegmentEvent('CallToOrder')}
            href={'tel:18556829231'}
            text={'Need Help? Call to Order'}
            data-testid={'pdp-call-to-order'} />
          <FeatureFlagWrapper
            ff={"Free_Shipping_Text"}
            childrenIfFalse={false}
          >
            <div className={styles.pdp_offer_free_shiping_wrapper}>
              <Typography size={1} font={'bold'} inline>
                FREE SHIPPING <Typography font={'regular'} inline>over</Typography> ${freeShippingThreshold}
              </Typography>
            </div>
          </FeatureFlagWrapper>
        </Form>
        {lastPurchaseDateForSku ?
          <Area intent={'subdued'} className={styles.lastPurchased} icon={'star'}>
            <Typography className={styles.lastPurchased_header} font={'bold'}>
              {`Last purchased on ${lastPurchaseDateForSku}`}
            </Typography>
            <TextLink className={styles.lastPurchased_link} size={0.875} chevron={'right'} href={`/ReviewSubmission?` +
              `pr_page_id=${item.skuBaseNumber}` +
              `&pr_merchant_id=${powerReviewsMerchantId}` +
              `&pr_merchant_group_id=${powerReviewsMerchantGroupId}`}>
              Write a Review
            </TextLink>
          </Area> : false}
        <div id={'pdp-add-to-cart-button'} />
        <div className={styles.pdp_atc_aids}>
          <TextLink size={0.875}
            className={styles.pdp_add_to_list}
            data-testid={'pdp-add-to-list'}
            onClick={(e) => {
              e.preventDefault()
              isIndexOptionKit
                ? showKitDetailsDrawer({ list: true })
                : !skuData.skuVariant
                  ? errorIndexOptions()
                  : showListDrawer([item])
            }} >
            <Svg size={0.875} tone={'subtle'} icon={'heart'} className={styles.pdp_atc_aid_icon} /> Add to a list
          </TextLink>

          <Share
            url={skuData.productPageUrl}
            subtitle={"Look what I found on Speedway Motors:"}
            title={skuData.title}
            tone={'subtle'}
            size={0.875}
            iconText={'Share'}
            className={styles.pdp_share}
            data-testid={'pdp-share'}
            segmentEvent={{
              event: 'Product Shared',
              properties: {
                share_via: 'link',
                // share_message: 'Hey, check out this item',
                // recipient: 'friend@example.com',
                product_id: skuData.skuBase,
                sku: skuData.skuBase,
                category: skuData.segmentCategory,
                name: skuData.title,
                brand: skuData.brand,
                variant: skuData.skuVariant || undefined,
                price: skuData.price,
                url: skuData.productPageUrl,
                image_url: (skuData.images || []).length ? `https:${skuData.images[0]}` : "",
                availability: skuData.availabilityEnum,
                rating: skuData.reviewCount ? skuData.rating : 0,
                reviews: skuData.reviewCount,
                audience_affinity: getSuperMarketAffinity(skuData),
              }
            }}
          />
        </div>
      </Fragment>
    </ErrorBoundary>
  )
}

const StyledAreaIcon = styled.div`
    background-color: ${props => props.backgroundColor};
    padding: 0.5rem;
    border-radius: 100%;
    margin-right: 1rem;
`

export default AddToCartForm
