import React from "react";
import Area from "Clutch/Molecules/Area";
import Typography from "Clutch/Atoms/Typography";
import TextLink from "Clutch/Atoms/TextLink";
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";
import styles from "./fitments.module.scss";
import { fitmentDisplayTypes } from "../determineFitmentType";
import VehicleDisplayText from "./vehicleDisplayText/vehicleDisplayText";
import OtherVehiclesThatFit from "./otherVehiclesThatFit/otherVehiclesThatFit";

const Fits = ({
  selectedVehicle,
  vehicleBasesThatFit,
  raceTypesThatFit,
  enginesThatFit,
  customerVehicles,
  showFitmentDrawer,
  fitmentType,
}) => {
  let areaProps = { intent: "positive", icon: "check" };
  if (fitmentType === fitmentDisplayTypes.universal)
    areaProps = { intent: "action", icon: "minus" };

  let headingText = "Guaranteed To Fit Your Stock";
  if (fitmentType === fitmentDisplayTypes.universal)
    headingText = "This part is Universal Fit";

  let modificationText = null;
  if (fitmentType === fitmentDisplayTypes.universal)
    modificationText = "Modification may be required";

  return (
    <ErrorBoundary>
      <Area
        {...areaProps}
        className={styles.vehicle_fitment}
        data-testid={`fitment_${fitmentType}`}
        centerIcon={false}
      >
        <Typography font={"bold"}>{headingText}</Typography>
        {modificationText &&  <Typography size={0.75} tone={'subtle'}>{modificationText}</Typography>}
        <VehicleDisplayText vehicle={selectedVehicle} />
        <OtherVehiclesThatFit
          selectedVehicle={selectedVehicle}
          vehicleBasesThatFit={vehicleBasesThatFit}
          raceTypesThatFit={raceTypesThatFit}
          enginesThatFit={enginesThatFit}
          customerVehicles={customerVehicles}
        />
        <TextLink
          className={styles.vehicle_fitment_link}
          size={0.875}
          chevron={"right"}
          onClick={() => showFitmentDrawer()}
          data-testid={`fitment_${fitmentType}_drawer_control`}
        >
          View Garage
        </TextLink>
      </Area>
    </ErrorBoundary>
  );
};

export default Fits;
