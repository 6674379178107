import { useState, useContext, useEffect } from "react";

import Typography from "Clutch/Atoms/Typography/Typography";
import Button from "Clutch/Molecules/Button/Button";
import Svg from "Clutch/Atoms/Svg";
import Textbox from "Clutch/Atoms/Form/Elements/Textbox";
import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import { sendFitmentFeedbackToZendesk } from "./sendFitmentFeedbackToZendesk";

import styles from "./engineFitmentFeedback.module.scss";

const FitmentFeedbackConfirmation = ({
  fitmentRow,
  closeFunc,
  skuNumber,
  engineId,
  vehicleBaseId,
  raceTypeId,
  marketId,
  animation
}) => {
  const hydrated = useUserStore((x) => x.context.hydrated);
  const emailAddress = useUserStore((x) => x.context.emailAddress);

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    if (hydrated && emailAddress)
      setUserEmail(emailAddress);
  }, [hydrated, emailAddress]);

  async function handleSubmit(userMessage, userEmailInput) {
    if (!userMessage) {
      setError("Enter a reason for complaint.");
      return;
    }
    if (!userEmail && !userEmailInput) {
      setError("Enter your email address.");
      return;
    }
    if (userEmail || userEmailInput) {
      setIsLoading(true);
      var input = {
        skuVariant: skuNumber,
        reporterEmail: userEmail ?? userEmailInput,
        reporterMessage: userMessage,
        engineDefinitionId: engineId,
      };

      if (vehicleBaseId) input.vehicleBaseId = vehicleBaseId;
      if (raceTypeId) input.raceTypeId = raceTypeId;
      if (marketId) input.marketId = marketId;

      var success = await sendFitmentFeedbackToZendesk(input);
      setSuccess(success);
      setIsLoading(false);
    }
  }

  if (!fitmentRow || !animation) return false;

  return (
    <div className={styles.confirmation} animation={animation.toString()} extraheight={(!userEmail).toString()}>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
          if (userEmail) {
            handleSubmit(e.target[0].value);
          } else {
            handleSubmit(e.target[1].value, e.target[0].value);
          }
        }}
      >
        <Typography font="bold" size={1.25}>
          Flag fitment for {fitmentRow}?
        </Typography>
        {!userEmail && (
          <Textbox
            label={"Enter your email address"}
            className={styles.textbox}
            fill
          />
        )}
        <Textbox
          label={"Reason for complaint"}
          className={styles.textbox}
          fill
        />
        <Button
          className={styles.complaintButton}
          intent={"action"}
          size={"small"}
          isWaiting={isLoading}
          disabled={success}
          segmentEvent={{ event: "engineFitmentFeedbackComplaintSubmission" }}
          type={"submit"}
          text={"Yes, Submit Complaint"}
        ></Button>
        {success && (
          <Typography>Feedback submitted successfully. Thank you!</Typography>
        )}
        {!success && error && (
          <Typography intent="negative">{error}</Typography>
        )}
      </form>

      <div className={styles.close} onClick={closeFunc}>
        <Svg icon="close" size={1} />
      </div>
    </div>
  );
};

export default FitmentFeedbackConfirmation;
