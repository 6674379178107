import React, { Fragment, useEffect, useState } from 'react'

import Area from 'Clutch/Molecules/Area'
import Svg from 'Clutch/Atoms/Svg'
import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import Skeleton from 'Clutch/Atoms/Skeleton'

import styles from './pricing.module.scss'
import styled from 'styled-components'
import classNames from 'classnames'

const Pricing = ({
  price,
  unit,
  hasPriceRange,
  strikeThroughPrice,
  savingsPrice,
  skuVariantNumber,
  quantityRequested,
  quantityBreaks,
  availability,
  availabilityDisplayCopy,
  isPurchaseable,
  alternativeProducts,
  speedwayAlternativeProducts,
  limitedQuantityDisplayCopy,
  loadingIndexOptionSelection,
  nonGarageSaleSkuVariant,
  garageSaleSkuVariants,
  showGarageSaleDrawer,
  garageSaleDisplayCopy,
  backgroundColor,
 }) => {

  const isDropShip = availability === "Factory Shipped"

  if (loadingIndexOptionSelection) {
    return (
      <>
        <div className={styles.pdp_offer_extra_spacer} />
        <div className={styles.pdp_offer_vert}>
          <Skeleton height={36} width={125} />
          <Skeleton height={24} width={60} className={styles.pdp_offer_pricing_availability} />
        </div>
      </>
    )
  }

  // I think google merchant gets confused and mistakenly flags us b/c of our html, so we're trying this to see if it goes away
  // don't change any display based upon this...
  availability ??= "";
  const availabilityClassName = `availability-${availability.replace(" ", "-")}`


  return (
    <Fragment>
      <div>
      <Typography size={0.75} tone={'subtle'} className={hasPriceRange ? styles.pdp_offer_spacer : styles.pdp_offer_extra_spacer}>
        {hasPriceRange ? 'Starting from' : ' '}
      </Typography>
      </div>
      <div className={styles.pdp_offer_vert}>
        {isPurchaseable ? <>
          <Typography size={1.5} tone={'contrast'} font={'bold'} inline>
            {price}
          </Typography>
          {price ?
            <Typography size={0.75} tone={'subtle'} inline>
              {unit}
            </Typography> : false}
        </> : false}
        <Typography className={classNames(styles.pdp_offer_pricing_availability, availabilityClassName)} size={1} tone={'subtle'} font={'bold'} inline intent={availability === 'In Stock' ? 'positive' : null}>
          {availability}
        </Typography>
      </div>
      {strikeThroughPrice && isPurchaseable ?
        <Fragment>
          <Typography className={styles.pdp_offer_pricing_discount} size={0.875} tone={'subtle'} inline strikethrough>
            {strikeThroughPrice}
          </Typography>
          <Typography size={0.875} intent={'positive'} font={'bold'} inline>
            {`Save ${savingsPrice}`}
          </Typography>
        </Fragment>
        : false}
      {!isDropShip?
      <>
      {availabilityDisplayCopy && availabilityDisplayCopy.length ?
        <Fragment>
          {!isDropShip? 
            <Area intent={'warning'} className={classNames(styles.pdp_offer_spacer, availabilityClassName)}>
              <Typography size={0.875} font={'bold'}>
                {availabilityDisplayCopy}
              </Typography>
            </Area> 
            : 
            false}
          {(alternativeProducts || []).length ?
            <div className={styles.shipping_card}>
              <StyledAreaIcon backgroundColor={backgroundColor} className={styles.shipping_icon}>
                <Svg icon={'timer'} size={1.5} className={styles.shipping_icon_svg} />
              </StyledAreaIcon>
              <span className={styles.shipping_card_details}>
                <Typography size={1} font={'bold'}>
                  Get something sooner
                </Typography>
                <TextLink size={0.875} scrollTo={'pdp_alternates'} intent={'action'}>
                  Browse In Stock and Ready to Ship Alternatives
                </TextLink>
              </span>
            </div> : false}
        </Fragment> : false}
      {(speedwayAlternativeProducts || []).length ?
        <div className={styles.shipping_card}>
          <Svg icon={'SpeedwayMotors_Wheel_Full_Color'} size={4} className={styles.wheel_icon_svg} brand={'primary'} />
          <span className={styles.speedway_alternatives_card_details}>
            <Typography size={1} font={'bold'}>
              Shop Speedway Motors Alternatives
            </Typography>
            <TextLink size={0.875} scrollTo={'pdp_speedway_alternates'} intent={'action'}>
              Starting at ${Math.min(...(speedwayAlternativeProducts || []).map(alt => alt.price))}
            </TextLink>
          </span>
        </div> : false}
      {skuVariantNumber !== null && skuVariantNumber !== "" && (quantityBreaks || []).length ?
        <div>
          <div className={styles.quantity_break_amount}>
            {quantityBreaks.map(x =>
              <Typography size={0.875}>Buy {x.priceLevelQuantity} or More:</Typography>
            )}
          </div>
          <div className={styles.quantity_break_amount}>
            {quantityBreaks.map(x =>
              <Typography font={'bold'} size={0.875}>{x.price} <Typography inline font={'subtle'} size={0.875}> {unit} </Typography> </Typography>
            )}
          </div>
        </div>
        :
        false
      }
      {limitedQuantityDisplayCopy && limitedQuantityDisplayCopy.length ?
        <Area brand={'secondarypalette_purple'} className={styles.pdp_offer_spacer}>
          <Typography size={0.875} font={'bold'}>
            {limitedQuantityDisplayCopy}
          </Typography>
        </Area> : false}
      {garageSaleDisplayCopy && garageSaleDisplayCopy.length ?
        <Area intent={'warning'} className={styles.pdp_offer_spacer}>
          <Typography size={0.875} font={'bold'}>
            {garageSaleDisplayCopy}
          </Typography>
        </Area> : false}
      {nonGarageSaleSkuVariant && nonGarageSaleSkuVariant.productPageUrl ?
        <TextLink className={styles.pdp_offer_pricing_garageSaleLink} size={0.875} href={nonGarageSaleSkuVariant.productPageUrl} tone={'subtle'} underline>
          Buy it brand new {nonGarageSaleSkuVariant.priceString ? `for ${nonGarageSaleSkuVariant.priceString}` : ''}
        </TextLink> : false}
      {(garageSaleSkuVariants || []).length ?
        <TextLink className={styles.pdp_offer_pricing_garageSaleLink} size={0.875} onClick={() => showGarageSaleDrawer()} tone={'subtle'}>
          {`Garage Sale alternatives${garageSaleSkuVariants.some(x => x.price) ? `: from $${Math.min(...garageSaleSkuVariants.filter(x => x.price).map(x => x.price))}` : ''}`}
        </TextLink> : false}
    </> : false}
    </Fragment>
  )
}

const StyledAreaIcon = styled.div`
    background-color: ${props => props.backgroundColor};
    padding: 0.5rem;
    border-radius: 100%;
    margin-right: 1rem;
`

export default Pricing
