import React, { useEffect, useContext } from "react";
import { useInView } from "react-intersection-observer";
import Image from "next/image";

import { ProductPageContext } from "../../contexts/ProductPageContext";

import Typography from "Clutch/Atoms/Typography";
import TextLink from "Clutch/Atoms/TextLink";
import Card from "Clutch/Atoms/Card";
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";
import Divider from "Clutch/Atoms/Divider";
import Rating from "Clutch/Atoms/Rating";
import Skeleton from "Clutch/Atoms/Skeleton";
import { useRecommendationStore } from "Clutch/Stores/RecommendationStore/RecommendationStore";
import RecommendationSection from "Clutch/Organisms/RecommendationSection/RecommendationSection";
import styles from "./asideRecommendations.module.scss";

const AsideRecommendations = ({ testData, keywords }) => {
  const section = "PDP_Aside";
  const setRecommendationSectionInView = useRecommendationStore(x => x.setRecommendationSectionInView)

  // todo this is all going to be baked into the
  // RecommendationSection component once we're able to use that
  // so this will eventually go away!!!
  // in fact it might already be ready to go, but I don't remember for sure...
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: "200px 0px",
  });
  useEffect(() => {
    if (inView) {
      setRecommendationSectionInView(section);
    }
  }, [inView]);

  return (
    <ErrorBoundary>
      <RecommendationSection section={section} displayType={"Vertical"} skeletonDisplayType={"Vertical"} numberOfSkeletons={3} testData={testData} spacingSize={'None'} keywords={keywords} isCard useSubtitle useDivider useItemCount />
    </ErrorBoundary>
  );
};

export default AsideRecommendations;
