import React, {useContext} from 'react'

import Area from 'Clutch/Molecules/Area'
import Svg from 'Clutch/Atoms/Svg'
import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'

import { useVehicleStore } from 'Clutch/Stores/VehicleStore'

import styles from './fitments.module.scss'

const SelectVehicle = ({}) => {
  const openAddOrSelectVehicleExperience = useVehicleStore(state => state.openAddOrSelectVehicleExperience)

  return (
    <ErrorBoundary>
      <Area brand={'secondary'} className={styles.vehicle_fitment} icon={'universal_fit'}>
        <Typography className={styles.vehicle_fitment_header} font={'bold'}>
          Select a vehicle to verify Fitment
        </Typography>
        <TextLink className={styles.vehicle_fitment_link} size={0.875} chevron={'right'} onClick={() => openAddOrSelectVehicleExperience()}  data-testid={`pdp-select-car-for-fitment`}>
          Select Vehicle
        </TextLink>
      </Area> 
    </ErrorBoundary>
  )
}

export default SelectVehicle