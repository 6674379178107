import React, { useState, useEffect, useContext } from 'react'
import ClassNames from 'classnames'

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary/ErrorBoundary'
import Button from 'Clutch/Molecules/Button'

import { ProductPageContext } from '../../contexts/ProductPageContext'
import { AddToCartExperienceContext } from 'Clutch/Contexts/AddToCartExperienceContext/AddToCartExperienceContext'

import { GetUserGuid } from 'Clutch/Utilities/CookieUtilties/UserCookieUtilities'
import { getSuperMarketAffinity } from 'Clutch/Utilities/Instrumentation/SegmentUtilities/segmentFunctions'

import styles from './mobileStickyAtc.module.scss'

const MobileStickyFooter = ({ triggerId, testing }) => {
  const [isVisible, setVisibility] = useState(testing ? false : null);

  const handleScroll = () => {
    var triggerElement = document.getElementById(triggerId);

    if (triggerElement) {
      var triggerOffset = triggerElement.offsetTop;
      const position = window.pageYOffset;

      if (triggerOffset < position) {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const productPageContext = useContext(ProductPageContext)
  const addToCartExperienceContext = useContext(AddToCartExperienceContext)

  const item = {
    skuVariantNumber: productPageContext.skuVariantNumber,
    skuBaseNumber: productPageContext.skuBaseNumber,
    quantityRequested: parseInt(productPageContext.quantityRequested),
    title: productPageContext.title,
    images: productPageContext.images,
    segmentCategory: productPageContext.segmentCategorization,
    brand: productPageContext.brand?.brandName,
    price: productPageContext.retailLowPrice,
    url: productPageContext.productPageUrl,
  }

  if (isVisible == null)
    return false;

  return (
    <ErrorBoundary>
      {productPageContext.isPurchaseable ?
        <div className={styles.atc_container} id={`pdp_atc_sticky_container`}>
          <Button
            inline
            intent={'positive'}
            size={'small'}
            icon={'add_to_cart'}
            className={ClassNames(styles.atc_button, !isVisible ? styles.slide : false)}
            data-testid={'mobile_sticky_atc_btn'}
            segmentEvent={{
              event: 'Product Add Started',
              properties: {
                cart_id: GetUserGuid(),
                product_id: productPageContext.skuBaseNumber,
                sku: productPageContext.skuBaseNumber,
                name: productPageContext.title,
                variant: productPageContext.skuVariantNumber,
                quantity: productPageContext.quantityRequested,
                brand: productPageContext.brand ? productPageContext.brand.brandName || '' : '',
                category: productPageContext.segmentCategorization,
                price: productPageContext.retailLowPrice,
                url: productPageContext.productPageUrl,
                image_url: (productPageContext.images || []).length ? `https:${productPageContext.images[0]}` : "",
                availability: productPageContext.availabilityEnum,
                rating: productPageContext.reviewCount ? productPageContext.rating : 0,
                reviews: productPageContext.reviewCount,
                audience_affinity: getSuperMarketAffinity(productPageContext)
              }
            }}
            onClick={
              (e) => {
                e.preventDefault()
                if (productPageContext.skuVariantNumber) {
                  productPageContext.isIndexOptionKit
                    ? addToCartExperienceContext.showKitDetailsDrawer()
                    : addToCartExperienceContext.addToCart([item])
                } else {
                  productPageContext.errorIndexOptions()
                }
              }
            } />
        </div>
        :
        false
      }
    </ErrorBoundary>
  )
}

export default MobileStickyFooter