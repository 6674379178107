import React from "react";

import Area from "Clutch/Molecules/Area";
import Typography from "Clutch/Atoms/Typography";
import TextLink from "Clutch/Atoms/TextLink";
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";

import styles from "./fitments.module.scss";
import VehicleDisplayText from "./vehicleDisplayText/vehicleDisplayText";
import OtherVehiclesThatFit from "./otherVehiclesThatFit/otherVehiclesThatFit";
import DoesNotFitVehicleDisplayText from "./doesNotFitVehicleDisplayText/doesNotFitVehicleDisplayText";

const DoesntFit = ({
  selectedVehicle,
  vehicleBasesThatFit,
  raceTypesThatFit,
  enginesThatFit,
  breadcrumb,
  showFitmentDrawer,
  shopUrl,
  partialFit,
}) => {
  const breadcrumbItem = (breadcrumb || [])
    .sort((a, b) => {
      return parseInt(a.ordinality) - parseInt(b.ordinality);
    })
    .filter((item) => item.url && item.url != "/")[0];

  const hasAnyVehiclesThatFit =
    (vehicleBasesThatFit && vehicleBasesThatFit.length) ||
    (raceTypesThatFit && raceTypesThatFit.length) ||
    (enginesThatFit && enginesThatFit.length);

  let vehicleDisplayForFitmentLink;

  if (selectedVehicle.raceTypeId)
    vehicleDisplayForFitmentLink = selectedVehicle.raceName;
  else if (selectedVehicle.model)
    vehicleDisplayForFitmentLink = selectedVehicle.model;
  else if (selectedVehicle.engineDisplayName)
    vehicleDisplayForFitmentLink = selectedVehicle.engineDisplayName;

  return (
    <ErrorBoundary>
      <Area
        intent={"negative"}
        className={styles.vehicle_fitment}
        icon={"warning"}
        centerIcon={false}
        data-testid={`fitment_doesntFit`}
      >
        <Typography className={styles.vehicle_fitment_header} font={"bold"}>
          This Part May Not Fit
        </Typography>
        <Typography size={0.875}>
          {`Does not fit your stock `}
          <DoesNotFitVehicleDisplayText
            vehicle={selectedVehicle}
            partialFit={partialFit}
          />
        </Typography>

        {hasAnyVehiclesThatFit ? (
          <ErrorBoundary>
            <OtherVehiclesThatFit
              vehicleBasesThatFit={vehicleBasesThatFit}
              raceTypesThatFit={raceTypesThatFit}
              enginesThatFit={enginesThatFit}
            />
          </ErrorBoundary>
        ) : (
          false
        )}

        {breadcrumbItem && shopUrl && shopUrl.length ? (
          <TextLink
            className={styles.vehicle_fitment_link}
            size={0.875}
            href={shopUrl}
            underline
          >
            {`${vehicleDisplayForFitmentLink} ${breadcrumbItem.displayText} parts`}
          </TextLink>
        ) : (
          false
        )}
        <TextLink
          className={styles.vehicle_fitment_link}
          size={0.875}
          chevron={"right"}
          onClick={() => showFitmentDrawer()}
        >
          View Garage
        </TextLink>
      </Area>
    </ErrorBoundary>
  );
};

export default DoesntFit;
