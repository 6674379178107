import React, { useContext } from 'react'

import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import Svg from 'Clutch/Atoms/Svg'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'

import styles from './bullets.module.scss'


const Bullets = ({ bullets }) => {
  if (!bullets || bullets.length === 0)
    return false

  const maxCharacterCount = 400
  const maxBulletCount = 3

  let bulletsToUse = bullets.slice(0, maxBulletCount)

  for (let i = maxBulletCount; i > 1; i--) {
    if (bulletsToUse.map(a => a.length).reduce((a, b) => a + b, 0) > maxCharacterCount) {
      bulletsToUse = bullets.slice(0, i - 1)
    } else {
      break
    }
  }

  return (
    <ErrorBoundary>
      <ul className={styles.pdp_offer_bullets}>
        {bulletsToUse.map((bullet, i) =>
          <Typography key={`bullet-${i}:${bullet.substr(0, 20)}`} as={'li'} size={0.875}>
            {bullet}
          </Typography>
        )}
      </ul>
      <TextLink className={styles.pdp_offer_bullets_see_more} data-testid={'offer_section_see_more'} size={0.875} intent={'action'} scrollTo={'pdp_summary'}>
        See More
        {/* <Svg className={styles.pdp_offer_bullets_see_more_chevron} icon={'chevron'} size={0.875}  intent={'action'}/> */}
      </TextLink>
    </ErrorBoundary>
  )
}

export default Bullets