import React, { useContext } from 'react'

import { ProductPageContext } from '../../../contexts/ProductPageContext';

import Typography from 'Clutch/Atoms/Typography'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import TextLink from 'Clutch/Atoms/TextLink'
import styles from './specs.module.scss'

const Specs = ({ toggleAccordion }) => {

  const productPageContext = useContext(ProductPageContext)

  if (!productPageContext.specs || productPageContext.specs.length == 0) {
    return false;
  }

  const indexOptions = productPageContext.indexOptions.map(indexOption => indexOption.displayName)
  return (
    <ErrorBoundary>
      <table>
        <tbody className={styles.spec_table}>
          {productPageContext.specs
            .filter((item) =>
              item.isKeySpec
            )
            .slice(0, 5)
            .sort((a, b) => {
              return parseInt(a.ordinality) - parseInt(b.ordinality);
            })
            .map((item, i) =>
              <ErrorBoundary key={`spec_sum_${item.title}_${i}`}>
                <tr>
                  <Typography as={'td'} className={styles.spec_title} size={0.875} font={'bold'} tone={'subtle'}>
                    {item.title}
                  </Typography>
                  {item.requiresVariantSelection ? (
                    <TextLink as={'td'} className={styles.spec_value} size={0.875} tone={'subtle'} scrollTo={'pdp_offer'}>
                      {`Select 
                        ${indexOptions.length > 1 ?
                          indexOptions.map(x => x).slice(0, -1).join(', ')
                          + ' and '
                          + indexOptions.map(x => x)[indexOptions.length - 1]
                          :
                          indexOptions[0]
                        }`}
                    </TextLink>
                  ) : (
                    <SpecValue title={item.title} value={item.value} />
                  )}
                </tr>
              </ErrorBoundary>
            )
          }
        </tbody>
      </table>
      <TextLink size={0.875} intent={'action'} onClick={() => productPageContext.scrollTo('pdp_specs', 'Specs')} className={styles.see_more}>
        See more specs
      </TextLink>
    </ErrorBoundary>
  )
}
const SpecValue = ({ title, value }) => {
  if (title == "CARB EO#") {
    return (
      <TextLink
        size={0.875}
        className={styles.spec_value}
        underline
        targetBlank
        href={`https://ww2.arb.ca.gov/sites/default/files/aftermarket/devices/eo/${value.toLowerCase()}.pdf`}>
        {value}
      </TextLink>
    )
  }
  return (
    <Typography as={'td'} className={styles.spec_value} size={0.875}>
      {value}
    </Typography>
  )
}

export default Specs