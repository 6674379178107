import React, {useContext} from 'react';
import Typography from 'Clutch/Atoms/Typography';
import TextLink from 'Clutch/Atoms/TextLink';
import Rating from 'Clutch/Atoms/Rating';
import styles from './rating.module.scss'
import { ProductPageContext } from "../../../contexts/ProductPageContext";


const RatingAndQuestionsOverview = ({ ratingValue, reviewCount, numberOfQuestions, skuBaseNumber, powerReviewsMerchantId, powerReviewsMerchantGroupId, scrollTo }) => {

    const productPageContext = useContext(ProductPageContext)

  return (
    <div>
      <Rating ratingValue={ratingValue} />
      <span className={styles.rating_spacer}></span>
      
      {reviewCount ?
          <TextLink
              size={0.75}
              tone={'subtle'}
              onClick={() => {

                  console.log("productPageContext.summarizedReview", productPageContext.summarizedReview)

                  const shouldOpenAccordion = !productPageContext.summarizedReview;
                  scrollTo('pdp_ratings', 'RatingsAndReviews', false, shouldOpenAccordion);
              }}
              inline
              underline>
              {reviewCount + ` ${reviewCount === 1 ? 'Review' : 'Reviews'}`}
          </TextLink> :
        <TextLink size={0.75} tone={'subtle'} inline underline
          href={`/ReviewSubmission?` +
            `pr_page_id=${skuBaseNumber}` +
            `&pr_merchant_id=${powerReviewsMerchantId}` +
            `&pr_merchant_group_id=${powerReviewsMerchantGroupId}`}>
          Write a Review
        </TextLink>
      }

      <Typography size={0.75} tone={'subtle'} inline>
        {' | '}
      </Typography>

      {numberOfQuestions ?
        <TextLink size={0.75} tone={'subtle'} onClick={() => { scrollTo('pdp_qna', 'QuestionsAndAnswers') }}  inline underline>
          {numberOfQuestions + ` ${numberOfQuestions === 1 ? 'Question' : 'Questions'}`}
        </TextLink> :
        <TextLink size={0.75} tone={'subtle'} onClick={() =>{ scrollTo('pdp_qna', 'QuestionsAndAnswers') }} inline underline>
          Ask a Question
        </TextLink>
      }
    </div>
  );
};

export default RatingAndQuestionsOverview;
