import React, { Fragment } from 'react'
import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import Skeleton from 'Clutch/Atoms/Skeleton'
import styles from './title.module.scss'

const Title = ({ partNumber, title, brand, loadingIndexOptionSelection }) => {
  return (

    <Fragment>
      {/* BRAND */}
      {brand && brand.brandName ?
        <Fragment>
          {brand.brandLinkUrl ?
            <TextLink size={0.875} tone={'subtle'} font={'regular'} href={brand.brandLinkUrl} inline underline>
              {brand.brandName}
            </TextLink> :
            <Typography size={0.875} tone={'subtle'} font={'regular'} inline>
              {brand.brandName}
            </Typography>
          }
          <Typography size={0.875} tone={'subtle'} inline>
            {' | '}
          </Typography>
        </Fragment> : false
      }

      {/* PART NUMBER */}
      {partNumber ?
          <Typography size={0.875} tone={'subtle'} font={'regular'} inline data-testid={'pdp_part_number'}>
            {`#${partNumber}`}
          </Typography> : false
      }

      {/* TITLE */}
      {loadingIndexOptionSelection ? <><Skeleton height={30} fullWidth /> <Skeleton height={30} width={250} /></> :
        <Typography as={'h1'} size={1.5} tone={'contrast'} font={'bold'}>
          {title}
        </Typography>}

      <div className={styles.title_spacer} />
    </Fragment>
  )
}

export default Title