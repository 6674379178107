import VehicleDisplayText from "../vehicleDisplayText/vehicleDisplayText";

const OtherVehiclesThatFit = ({
  selectedVehicle,
  vehicleBasesThatFit,
  raceTypesThatFit,
  enginesThatFit,
  customerVehicles,
}) => {
  return (
    <>
      {(vehicleBasesThatFit || []).map((vehicle, i) => {
        const matchingVehicle = customerVehicles.find(
          (customerVehicle) => customerVehicle.projectId == vehicle.key
        );

        if (!matchingVehicle) return false;
        if (selectedVehicle.projectId === matchingVehicle.projectId)
          return false;

        return <VehicleDisplayText vehicle={matchingVehicle} />;
      })}
      {(raceTypesThatFit || []).map((vehicle, i) => {
        const matchingVehicle = customerVehicles.find(
          (customerVehicle) => customerVehicle.projectId == vehicle.key
        );

        if (!matchingVehicle) return false;
        if (selectedVehicle.projectId === matchingVehicle.projectId)
          return false;

        return <VehicleDisplayText vehicle={matchingVehicle} />;
      })}
      {(enginesThatFit || []).map((vehicle, i) => {
        const matchingVehicle = customerVehicles.find(
          (customerVehicle) => customerVehicle.projectId == vehicle.key
        );

        if (!matchingVehicle) return false;
        if (selectedVehicle.projectId === matchingVehicle.projectId)
          return false;

        return <VehicleDisplayText vehicle={matchingVehicle} />;
      })}
    </>
  );
};

export default OtherVehiclesThatFit;
