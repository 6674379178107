import React, { useContext, useState, useEffect } from 'react'

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'

import useFeatureFlag from 'Clutch/Hooks/useFeatureFlag'
import RecommendationSection from "Clutch/Organisms/RecommendationSection/RecommendationSection";
import { RecommendationProvider } from "Clutch/Stores/RecommendationStore/RecommendationStore"
import { ProductPageContext } from '../../contexts/ProductPageContext';
import { useRecommendationStore } from "Clutch/Stores/RecommendationStore/RecommendationStore";

import styles from './compare.module.scss'

const ProductCompare = ({ stickyHeaderRef }) => {

  const compareEnabled = useFeatureFlag('PDP_Compare_Tool')
  const context = useContext(ProductPageContext)

  if (!!!compareEnabled)
    return false

  //adding these guards for Carson Smith, need to wire up blacklist recommendations for compare tool
  if (context.skuBaseNumber == "9105415")
    return false

  if (context.skuBaseNumber == "9101034")
    return false

  return (
    <div ref={stickyHeaderRef}>
      <ErrorBoundary>
      <RecommendationProvider
          sections={['PDP_Compare']}
          skuBaseNumbers={[context.skuBaseNumber]}
          skuVariantNumbers={context.skuVariantNumber ? [context.skuVariantNumber] : undefined}>
        <div className={styles.product_compare_card}>
          <InnerCompare/>
        </div>
        </RecommendationProvider>
      </ErrorBoundary>
    </div>
  )
}

const InnerCompare = () => {

  const isLoading = useRecommendationStore(x => x.isLoading)
  const recommendationData = useRecommendationStore(x => x.recommendationData)  


  const context = useContext(ProductPageContext)
  
    useEffect(() => {
    if(isLoading || !isLoading && recommendationData?.recommendationSections?.length > 0) {
      context.setIsCompareToolPresent(true)
    } else if (!isLoading && !(recommendationData?.recommendationSections?.length > 0)) {
      context.setIsCompareToolPresent(false)
    }
  }, [isLoading, recommendationData])

  return <RecommendationSection section={'PDP_Compare'} numberOfSkeletons={7} />
}

export default ProductCompare