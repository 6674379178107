import React, { useContext } from 'react'
import Image from 'next/image'


import { ProductPageContext } from '../../contexts/ProductPageContext'

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Typography from 'Clutch/Atoms/Typography'
import Divider from 'Clutch/Atoms/Divider'
import Drawer from 'Clutch/Molecules/Drawer'

import styles from './inStorePickupDrawer.module.scss'

const InStorePickupDrawer = ({ }) => {
  const productPageContext = useContext(ProductPageContext)
  return (
    <Drawer
      location={'right'}
      width={640}
      open={productPageContext.isInStorePickupDrawerOpen}
      closeFunction={() => productPageContext.hideInStorePickupDrawer()}>
      <div className={styles.isp_info_container}>
        <Typography size={1.25} font={'heading'} className={styles.fitment}>
          Store Pickup Availability
        </Typography>
        <Divider className={styles.divider} />
        {productPageContext.freeShippingAndAvailability && (productPageContext.freeShippingAndAvailability.warehouseAvailability || []).map(warehouse =>
          <ErrorBoundary key={`warehouse_${warehouse.warehouseId}`}>
            {
              warehouse.warehouseId === "SMI-LNK" ?
                <>
                  {
                    warehouse.availability === 'InStock' ?
                      warehouse.availabilityString === 'Limited qty' ?
                        <Typography size={1} font={'heading'} intent={'warning'} className={styles.availability} data-testid={`${warehouse.warehouseId}_limited`}>
                          Limited Quantity
                        </Typography>
                        :
                        <Typography size={1} font={'heading'} intent={'positive'} className={styles.availability} data-testid={`${warehouse.warehouseId}_available`}>
                          Available
                        </Typography>
                      :
                      <Typography size={1} font={'heading'} tone={'subtle'} className={styles.availability} data-testid={`${warehouse.warehouseId}_unavailable`}>
                        Unavailable
                      </Typography>
                  }
                  <Typography size={1} font={'heading'} className={styles.location_header}>Lincoln, NE</Typography>
                  <Typography size={0.875} font={'heading'} tone={'subtle'}>340 Victory Ln</Typography>
                  <Typography size={0.875} font={'heading'} tone={'subtle'}>Lincoln, NE 68528</Typography>
                  <Divider className={styles.divider} />
                </> : false
            }
            {
              warehouse.warehouseId === "SMI-PHX" ?
                <>
                  {
                    warehouse.availability === 'InStock' ?
                      warehouse.availabilityString === 'Limited qty' ?
                        <Typography size={1} font={'heading'} intent={'warning'} className={styles.availability} data-testid={`${warehouse.warehouseId}_limited`}>
                          Limited Quantity
                        </Typography>
                        :
                        <Typography size={1} font={'heading'} intent={'positive'} className={styles.availability} data-testid={`${warehouse.warehouseId}_available`}>
                          Available
                        </Typography>
                      :
                      <Typography size={1} font={'heading'} tone={'subtle'} className={styles.availability} data-testid={`${warehouse.warehouseId}_unavailable`}>
                        Unavailable
                      </Typography>
                  }
                  <Typography size={1} font={'heading'} className={styles.location_header}>Tolleson, AZ</Typography>
                  <Typography size={0.875} font={'heading'} tone={'subtle'}>8701 W McDowell Rd</Typography>
                  <Typography size={0.875} font={'heading'} tone={'subtle'}>Tolleson, AZ 85353</Typography>
                  <Divider className={styles.divider} />
                </> : false
            }
          </ErrorBoundary>
        )}
      </div>
    </Drawer>
  )
}

export default InStorePickupDrawer