import React, { Fragment, useState, useEffect, useContext, useMemo, useRef } from 'react'

import { ProductPageContext } from '../../contexts/ProductPageContext'

import { TrackingContext } from 'Clutch/Contexts/TrackingContext/TrackingContext'
import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import { AddToCartExperienceContext } from 'Clutch/Contexts/AddToCartExperienceContext/AddToCartExperienceContext'
import { getSuperMarketAffinity } from 'Clutch/Utilities/Instrumentation/SegmentUtilities/segmentFunctions'

import VehicleFitment from './vehicleFitment/vehicleFitment'
import Shipping from './shipping/shipping'
import AddToCartForm from './atcForm/atcForm'
import NonSaleableAddToCartForm from './atcForm/nonSaleableAtcForm'
import Pricing from './pricing/pricing'
import RatingAndQuestionsOverview from './rating/rating'
import Bullets from './bullets/bullets'
import Title from './title/title'

import Divider from 'Clutch/Atoms/Divider'
import Skeleton from 'Clutch/Atoms/Skeleton'
import IndexOptions from 'Clutch/Components/IndexOptions'

import LayerColorFinder from 'Clutch/Utilities/LayerColorFinder'

import styles from './offer.module.scss'

const Offer = () => {
  const renderCounter = useRef(0);
  renderCounter.current = renderCounter.current + 1
  console.debug(`Offer Component Renders: ${renderCounter.current}`)
  const productPageContext = useContext(ProductPageContext)
  const userVin = useUserStore((x) => x.context.userVin);
  const userGuid = useUserStore((x) => x.context.userGuid);
  const zipCode = useUserStore((x) => x.context.zipCode);
  const updateUserZipCode = useUserStore((x) => x.updateUserZipCode);

  const addToCartExperienceContext = useContext(AddToCartExperienceContext)
  const { segmentReady } = useContext(TrackingContext)

  const [isWaiting, setIsWaiting] = useState(false)

  useEffect(() => {
    if (productPageContext.isIndexOptionKit) {
      addToCartExperienceContext.populateKitDetailsDrawer(productPageContext.kitComponents, productPageContext.skuVariantNumber, productPageContext.quantityRequested, productPageContext.title, productPageContext.images, productPageContext.skuBaseNumber, productPageContext.brand?.brandName, productPageContext.priceDecimal, productPageContext.segmentCategorization, productPageContext.productPageUrl, productPageContext.availabilityEnum, productPageContext.rating, productPageContext.reviewCount, productPageContext.superMarketAffinity)
    }
  }, [productPageContext.isIndexOptionKit, productPageContext.quantityRequested])


  useEffect(() => {
    if (segmentReady &&
      !productPageContext.isPurchaseable &&
      (productPageContext.alternativeProducts || []).length > 0) {
      analytics.track('Product List Viewed', {
        'user_vin': `${userVin()}`,
        'web_user_guid': `${userGuid}`,
        'list_id': `pdp_offer_alts_0`,
        'category': `Alternative models available`,
        'products': (productPageContext.alternativeProducts || []).map((alt, i) => {
          return {
            'product_id': alt.skuBaseNumber,
            'sku': alt.skuBaseNumber,
            'name': alt.title,
            'price': alt.price,
            'position': i,
            'category': alt.segmentCategorization,
            'url': alt.productPageUrl,
            'image_url': alt.imageUrl,
            'quantity': 1,
            'brand': alt.brand,
            'availability': alt.availability,
            'rating': alt.reviewCount ? alt.rating : 0,
            'reviews': alt.reviewCount,
            'audience_affinity': getSuperMarketAffinity(alt)
          }
        })
      })
    }
  }, [productPageContext.isPurchaseable, segmentReady])

  const backgroundColor = LayerColorFinder(1)

  return (
    <Fragment>
      <Title partNumber={productPageContext.partNumber}
        title={productPageContext.titleH1 ?? productPageContext.title}
        brand={productPageContext.brand}
        loadingIndexOptionSelection={productPageContext.loadingIndexOptionSelection} />
      <RatingAndQuestionsOverview
        ratingValue={productPageContext.rating}
        reviewCount={productPageContext.reviewCount}
        numberOfQuestions={productPageContext.numberOfQuestions}
        skuBaseNumber={productPageContext.skuBaseNumber}
        powerReviewsMerchantId={productPageContext.powerReviewsMerchantId}
        powerReviewsMerchantGroupId={productPageContext.powerReviewsMerchantGroupId}
        toggleAccordion={productPageContext.toggleAccordion}
        scrollTo={productPageContext.scrollTo} />
      <Bullets bullets={productPageContext.bullets} />
      {productPageContext.indexOptions &&
        productPageContext.indexOptions.length > 0 &&
        productPageContext.bullets &&
        productPageContext.bullets.length > 0 ?
        <Divider key={'ix-option-divider'} className={styles.divider} /> : false}
      {useMemo(() =>
        <IndexOptions
          indexOptions={productPageContext.indexOptions}
          selectIndexOption={productPageContext.selectIndexOption}
          productPageId={productPageContext.productPageId}
          isIndexOptionErrorState={productPageContext.isIndexOptionErrorState}
          buttonCuttoffValue={10} />
        , [productPageContext.indexOptions, productPageContext.selectIndexOption, productPageContext.productPageId, productPageContext.isIndexOptionErrorState])
      }

      {productPageContext.indexOptions && productPageContext.indexOptions.length > 0 ?
        <Divider key={'ix-option-bottom'} className={styles.pdp_offer_option} /> : false}
      <Pricing
        price={productPageContext.price}
        unit={productPageContext.unit}
        hasPriceRange={productPageContext.hasPriceRange}
        strikeThroughPrice={productPageContext.strikeThroughPrice}
        savingsPrice={productPageContext.savingsPrice}
        skuVariantNumber={productPageContext.skuVariantNumber}
        quantityRequested={productPageContext.updateQuantityRequested}
        quantityBreaks={productPageContext.quantityBreaks}
        availability={productPageContext.availability}
        isPurchaseable={productPageContext.isPurchaseable}
        alternativeProducts={productPageContext.alternativeProducts}
        speedwayAlternativeProducts={productPageContext.speedwayAlternativeProducts}
        availabilityDisplayCopy={productPageContext.availabilityDisplayCopy}
        limitedQuantityDisplayCopy={productPageContext.limitedQuantityDisplayCopy}
        garageSaleDisplayCopy={productPageContext.garageSaleDisplayCopy}
        loadingIndexOptionSelection={productPageContext.loadingIndexOptionSelection}
        nonGarageSaleSkuVariant={productPageContext.nonGarageSaleSkuVariant}
        garageSaleSkuVariants={productPageContext.garageSaleSkuVariants}
        showGarageSaleDrawer={productPageContext.showGarageSaleDrawer}
        backgroundColor={backgroundColor}
      />
      <br />
      {productPageContext.isPurchaseable ?
        <Fragment>
          <AddToCartForm
            lastPurchaseDateForSku={productPageContext.lastPurchaseDateForSku}
            isIndexOptionKit={productPageContext.isIndexOptionKit}
            updateQuantityRequested={productPageContext.updateQuantityRequested}
            errorIndexOptions={productPageContext.errorIndexOptions}
            loadingIndexOptionSelection={productPageContext.loadingIndexOptionSelection}
            showKitDetailsDrawer={addToCartExperienceContext.showKitDetailsDrawer}
            showListDrawer={addToCartExperienceContext.showListDrawer}
            addToCart={addToCartExperienceContext.addToCart}
            skuData={{
              skuVariant: productPageContext.skuVariantNumber,
              skuBase: productPageContext.skuBaseNumber,
              title: productPageContext.title,
              subtitle: productPageContext.subtitle,
              productPageUrl: productPageContext.productPageUrl,
              images: productPageContext.images,
              quantityRequested: productPageContext.quantityRequested,
              segmentCategory: productPageContext.segmentCategorization,
              brand: productPageContext.brand?.brandName,
              price: productPageContext.retailLowPrice,
              superMarketAffinity: productPageContext.superMarketAffinity,
              rating: productPageContext.rating,
              reviewCount: productPageContext.reviewCount,
              availability: productPageContext.availability,
              availabilityEnum: productPageContext.availabilityEnum
            }}
            powerReviewsMerchantId={productPageContext.powerReviewsMerchantId}
            powerReviewsMerchantGroupId={productPageContext.powerReviewsMerchantGroupId}
            isWaiting={isWaiting}
            setIsWaiting={setIsWaiting}
            freeShippingThreshold={productPageContext.freeShippingThreshold}
          />
          {!productPageContext.excludeFromThisPartFitsMyVehicle ?
            <VehicleFitment
              checkFitment={productPageContext.checkFitment}
              fitment={productPageContext.fitment}
              breadcrumb={productPageContext.breadcrumb}
              showFitmentDrawer={productPageContext.showFitmentDrawer} /> : false
          }
        </Fragment> :
        <NonSaleableAddToCartForm
          breadcrumb={productPageContext.breadcrumb}
          skuVariantNumber={productPageContext.skuVariantNumber}
          skuBaseNumber={productPageContext.skuBaseNumber} />
      }
      <Shipping freeShippingAndAvailability={productPageContext.freeShippingAndAvailability}
        shippingEstimate={productPageContext.shippingEstimate}
        skuVariant={productPageContext.skuVariantNumber}
        getFreeShippingAndAvailability={productPageContext.getFreeShippingAndAvailability}
        setShippingInView={productPageContext.setShippingInView}
        showInStorePickupDrawer={productPageContext.showInStorePickupDrawer}
        availability={productPageContext.availability}
        updateUserZipCode={updateUserZipCode}
        userZipCode={zipCode}
        loadingFreeShippingAndAvailability={productPageContext.loadingFreeShippingAndAvailability}
        loadingShippingEstimate={productPageContext.loadingShippingEstimate}
        backgroundColor={backgroundColor}
        isPurchaseable={productPageContext.isPurchaseable}/>
    </Fragment>
  )
}

export default Offer