
import React, { Fragment } from 'react'

import Image from 'next/image'

import { RecommendationProvider } from "Clutch/Stores/RecommendationStore/RecommendationStore"

import RecommendationSection from 'Clutch/Organisms/RecommendationSection'

import Area from 'Clutch/Molecules/Area'
import Button from 'Clutch/Molecules/Button'

import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import Rating from 'Clutch/Atoms/Rating'
import ErrorBoundry from 'Clutch/UtilityComponents/ErrorBoundary'

import styles from './nonSaleableAtcForm.module.scss'
import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

const NonSaleableAddToCartForm = ({ breadcrumb, skuBaseNumber, skuVariantNumber }) => {

  const breadcrumbItem = (breadcrumb || []).sort((a, b) => {
    return parseInt(b.ordinality) - parseInt(a.ordinality);
  })
  .filter(item => item.url && item.url != '/')[0]

  return (
    <ErrorBoundry>
      <Area intent={'negative'} className={styles.nonsaleable_area}>
        <Typography size={1}>
          This item is currently not available
        </Typography>
      </Area>
      <Button className={styles.shopButton}
        intent={'action'}
        href={breadcrumbItem ? breadcrumbItem.url : '/shop/all'}
        text={breadcrumbItem ? `Shop all ${breadcrumbItem.displayText}` : 'Shop all Categories'}
        size={'small'}
        fill
        segmentEvent={createClickedSegmentEvent('ShopButton')}
        data-testid={'pdp-nonsaleable-shop'} /> 
      <div id={'pdp-add-to-cart-button'} />
      <div className={styles.nonsaleable_area}></div>
      <RecommendationProvider
          sections={['PDP_NonPurchasable_Alts']}
          skuBaseNumbers={[skuBaseNumber]}
          skuVariantNumbers={[skuVariantNumber]}>
          <RecommendationSection section={'PDP_NonPurchasable_Alts'} numberOfSkeletons={3} displayType={"Vertical"} skeletonDisplayType={"Vertical"}/>
        </RecommendationProvider>

    </ErrorBoundry>
  )
}

export default NonSaleableAddToCartForm
