import React, { Fragment, useState, useEffect, useContext, useMemo } from 'react'
import Image from 'next/image'
import ClassNames from 'classnames'

import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import Button from 'Clutch/Molecules/Button'
import Svg from 'Clutch/Atoms/Svg'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import { useLazyFeatureFlag } from 'Clutch/Hooks/useFeatureFlag/useFeatureFlag';

import { ProductPageContext } from '../../contexts/ProductPageContext'
import { AddToCartExperienceContext } from 'Clutch/Contexts/AddToCartExperienceContext/AddToCartExperienceContext'

import { GetUserGuid } from 'Clutch/Utilities/CookieUtilties/UserCookieUtilities'

import useFeatureFlag from 'Clutch/Hooks/useFeatureFlag'
import { validateDigitalAsset } from '../digitalAssets/digitalAssetUtils'
import styles from './stickyHeader.module.scss'
import { getSuperMarketAffinity } from 'Clutch/Utilities/Instrumentation/SegmentUtilities/segmentFunctions'
import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

const StickyHeaderContent = ({ inViewData }) => {
  const productPageContext = useContext(ProductPageContext)
  const addToCartExperienceContext = useContext(AddToCartExperienceContext)

  const compareEnabled = useFeatureFlag('PDP_Compare_Tool')
  const compareBoostEnabled = useLazyFeatureFlag(productPageContext?.brand?.brandName && !productPageContext?.brand?.isHouseBrand ? "PDP_Compare_Tool_Boosting" : "placeholderdontdelete")

  const [highlightedSection, setHighlightedSection] = useState('')

  let isPowerReviewsImageCarouselOpen = document.getElementsByClassName("pr-media-modal")?.length > 0;

  useEffect(() => {
    if (inViewData.summaryInView ) {
      setHighlightedSection('summary')
    } else if (inViewData.compareRefInView) {
      setHighlightedSection('compare')
    } else if (inViewData.summaryRecommendationsInView || inViewData.aplusCardContentInView) {
      setHighlightedSection('summary')
    } else if (inViewData.detailsClosedInView || inViewData.detailsOpenInView) {
      setHighlightedSection('details')
    } else if (inViewData.guidesDocsInView || inViewData.carbProp65InView) {
      setHighlightedSection('details_guides_docs')
    } else if (inViewData.specsInView) {
      setHighlightedSection('specs')
    } else if (inViewData.ratingsInView) {
      setHighlightedSection('ratings')
    } else if (inViewData.qnaInView) {
      setHighlightedSection('qna')
    } else if (inViewData.videosInView) {
      setHighlightedSection('videos')
    } else {
      setHighlightedSection('')
    }
  }, [inViewData]);

  const item = {
    skuVariantNumber: productPageContext.skuVariantNumber,
    skuBaseNumber: productPageContext.skuBaseNumber,
    quantityRequested: parseInt(productPageContext.quantityRequested),
    title: productPageContext.title,
    images: productPageContext.images,
    segmentCategory: productPageContext.segmentCategorization,
    brand: productPageContext.brand?.brandName,
    price: productPageContext.retailLowPrice,
    url: productPageContext.productPageUrl,
    availabilityEnum: productPageContext.availabilityEnum,
    rating: productPageContext.rating,
    reviewCount: productPageContext.reviewCount,
    superMarketAffinity: productPageContext.superMarketAffinity,
  }

  const breadcrumbItem = useMemo(() => (productPageContext.breadcrumb || []).sort((a, b) => {
    return parseInt(b.ordinality) - parseInt(a.ordinality);
  }).filter(item => item.url && item.url != '/')[0], [productPageContext.breadcrumb])

  const digitalAssets = productPageContext.digitalAssets?.filter(digitalAsset => validateDigitalAsset(digitalAsset))

  const sectionLinkClass = compareEnabled ? styles.section_link_crowded : styles.section_link

  if (isPowerReviewsImageCarouselOpen) {
    return false;
  }

  return (
    <ErrorBoundary>
      <span className={ClassNames(styles.sticky_header, isPowerReviewsImageCarouselOpen && styles.hide_sticky_header)}>
        <div className={styles.upper_section}>
          <div className={styles.product_header}>
            <Image
              src={`https:${(productPageContext.images || []).length ? productPageContext.images[0] : '//content.speedwaymotors.com/OtherImages/missingimage2.jpg'}`}
              width={48}
              height={48}
              alt={''}
            />
            <div className={styles.product_header_text}>
              <Typography size={0.875} >
                {productPageContext.title}
              </Typography>
              {productPageContext.partNumber ?
                <Typography size={0.75} tone={'subtle'} inline>
                  {`Part # ${productPageContext.partNumber} `}
                </Typography> : false}
              {productPageContext.brand && productPageContext.brand.brandName ?
                <Fragment>
                  <Typography size={0.75} tone={'subtle'} inline>
                    {'by '}
                  </Typography>
                  {productPageContext.brand.brandLinkUrl ?
                    <TextLink size={0.75} href={productPageContext.brand.brandLinkUrl} inline underline data-testid={'sh_brandLink'}>
                      {productPageContext.brand.brandName}
                    </TextLink> :
                    <Typography size={0.75} inline data-testid={'sh_brandText'}>
                      {productPageContext.brand.brandName}
                    </Typography>
                  }
                </Fragment> : false
              }
            </div>
          </div>

          <div className={styles.header_right_side}>
            {productPageContext.isPurchaseable ?
              <ErrorBoundary>
                {productPageContext.price ?
                  <div className={styles.pricing}>
                    <Typography inline font={'bold'} size={1.25}>
                      {productPageContext.price}
                    </Typography>
                    {productPageContext.unit ?
                      <Typography className={styles.superscript} inline tone={'subtle'} size={0.75}>
                        {' ' + productPageContext.unit}
                      </Typography>
                      :
                      false}
                  </div>
                  :
                  false}
                {productPageContext.skuVariantNumber ?
                  <Button
                    inline
                    intent={'positive'}
                    size={'small'}
                    className={styles.sticky_header_button}
                    text={'Add to Cart'}
                    data-testid={'sh_atc'}
                    segmentEvent={{
                      event: 'Product Add Started',
                      properties: {
                        cart_id: GetUserGuid(),
                        product_id: productPageContext.skuBaseNumber,
                        sku: productPageContext.skuBaseNumber,
                        name: productPageContext.title,
                        variant: productPageContext.skuVariantNumber,
                        quantity: productPageContext.quantityRequested,
                        brand: productPageContext.brand ? productPageContext.brand.brandName || '' : '',
                        category: productPageContext.segmentCategorization,
                        price: productPageContext.retailLowPrice,
                        url: productPageContext.productPageUrl,
                        image_url: (productPageContext.images || []).length ? `https:${productPageContext.images[0]}` : "",
                        availability: productPageContext.availabilityEnum,
                        rating: productPageContext.reviewCount ? productPageContext.rating : 0,
                        reviews: productPageContext.reviewCount,
                        audience_affinity: getSuperMarketAffinity(productPageContext)
                      }
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      productPageContext.isIndexOptionKit
                        ? addToCartExperienceContext.showKitDetailsDrawer()
                        : addToCartExperienceContext.addToCart([item])
                    }}
                  />
                  :
                  productPageContext.indexOptions && productPageContext.indexOptions.length ?
                    <Button
                      inline
                      intent={'action'}
                      size={'small'}
                      className={styles.sticky_header_button}
                      text={`
                        Select 
                        ${productPageContext.indexOptions.length > 1 ?
                          productPageContext.indexOptions.map(x => x.displayName).slice(0, -1).join(', ')
                          + ' and '
                          + productPageContext.indexOptions.map(x => x.displayName)[productPageContext.indexOptions.length - 1]
                          :
                          productPageContext.indexOptions[0].displayName
                        }
                      `}
                      data-testid={'sh_select_options'}
                      segmentEvent={createClickedSegmentEvent('SelectIndexOptions')}
                      onClick={(e) => {
                        e.preventDefault()
                        productPageContext.errorIndexOptions()
                      }} />
                    :
                    false
                }
                <Svg icon={'heart'}
                  size={2}
                  data-testid={'sh_save_to_list'}
                  onClick={(e) => {
                    e.preventDefault()
                    productPageContext.isIndexOptionKit
                      ? addToCartExperienceContext.showKitDetailsDrawer({ list: true })
                      : !productPageContext.skuVariantNumber
                        ? productPageContext.errorIndexOptions()
                        : addToCartExperienceContext.showListDrawer([item])
                  }} />
              </ErrorBoundary>
              :
              <ErrorBoundary>
                <Typography inline size={1.25} className={ClassNames(styles.pricing, styles.shop_all)}>
                  Product discontinued
                </Typography>
                <Button
                  inline
                  intent={'action'}
                  size={'small'}
                  href={breadcrumbItem ? breadcrumbItem.url : '/shop/all'}
                  text={breadcrumbItem ? `Shop all ${breadcrumbItem.displayText}` : 'Shop all Categories'}
                  data-testid={'sh_shop_all'}
                  segmentEvent={createClickedSegmentEvent('ShopAll')}
                  />
              </ErrorBoundary>
            }
          </div>
        </div>
        <TextLink
          inline
          size={0.875}
          font={highlightedSection == 'top' ? 'bold' : 'regular'}
          className={ClassNames(sectionLinkClass, highlightedSection == 'top' && styles.selected_section)}
          scrollTo={'__next'}>
          Top
        </TextLink>
        <TextLink
          inline
          size={0.875}
          font={highlightedSection == 'summary' ? 'bold' : 'regular'}
          className={ClassNames(sectionLinkClass, highlightedSection == 'summary' && styles.selected_section)}
          scrollTo={'pdp_summary'}>
          Summary
        </TextLink>
        {compareBoostEnabled && compareEnabled && productPageContext?.isCompareToolPresent ? 
          <TextLink
            inline
            size={0.875}
            font={highlightedSection == 'compare' ? 'bold' : 'regular'}
            className={ClassNames(sectionLinkClass, highlightedSection == 'compare' && styles.selected_section)}
            onClick={() => productPageContext.scrollTo('pdp_productcompare')}>
            {'Compare Similar Products'}
          </TextLink> : false
        }
        {productPageContext.tabs?.length || digitalAssets?.length ?
          <TextLink
            inline
            size={0.875}
            font={highlightedSection == 'details' ? 'bold' : 'regular'}
            className={ClassNames(sectionLinkClass, highlightedSection == 'details' && styles.selected_section)}
            onClick={() => { productPageContext.scrollTo('pdp_details', 'Details') }}>
            Details
          </TextLink> : false}
        {digitalAssets?.length ?
          <TextLink
            inline
            size={0.875}
            font={highlightedSection == 'details_guides_docs' ? 'bold' : 'regular'}
            className={ClassNames(sectionLinkClass, highlightedSection == 'details_guides_docs' && styles.selected_section)}
            onClick={() => { productPageContext.scrollTo('pdp_details_guides_docs', 'Details', true) }}>
            {'Guides & Documents'}
          </TextLink> : false}
        {productPageContext.specs && productPageContext.specs.length ?
          <TextLink
            inline
            size={0.875}
            font={highlightedSection == 'specs' ? 'bold' : 'regular'}
            className={ClassNames(sectionLinkClass, highlightedSection == 'specs' && styles.selected_section)}
            onClick={() => { productPageContext.scrollTo('pdp_specs', 'Specs') }}>
            Specifications
          </TextLink> : false}
        <TextLink
          inline
          size={0.875}
          font={highlightedSection == 'ratings' ? 'bold' : 'regular'}
          className={ClassNames(sectionLinkClass, highlightedSection == 'ratings' && styles.selected_section)}
          onClick={() => { productPageContext.scrollTo('pdp_ratings', 'RatingsAndReviews') }}>
          {'Ratings & Reviews'}
        </TextLink>
        <TextLink
          inline
          size={0.875}
          font={highlightedSection == 'qna' ? 'bold' : 'regular'}
          className={ClassNames(sectionLinkClass, highlightedSection == 'qna' && styles.selected_section)}
          onClick={() => { productPageContext.scrollTo('pdp_qna', 'QuestionsAndAnswers') }}>
          {'Questions & Answers'}
        </TextLink>
        {(productPageContext.videos && productPageContext.videos.length)
          || (productPageContext.articles && productPageContext.articles.length) ?
          <TextLink
            inline
            size={0.875}
            font={highlightedSection == 'videos' ? 'bold' : 'regular'}
            className={ClassNames(sectionLinkClass, highlightedSection == 'videos' && styles.selected_section)}
            onClick={() => { productPageContext.scrollTo('pdp_videosAndArticles', 'VideosAndArticles') }}>
            {'Videos & Articles'}
          </TextLink> : false}
        {compareEnabled && !compareBoostEnabled && productPageContext?.isCompareToolPresent ?
          <TextLink
            inline
            size={0.875}
            font={highlightedSection == 'compare' ? 'bold' : 'regular'}
            className={ClassNames(sectionLinkClass, highlightedSection == 'compare' && styles.selected_section)}
            onClick={() => { productPageContext.scrollTo('pdp_productcompare') }}>
            {'Compare Similar Products'}
          </TextLink> : false}
      </span>
    </ErrorBoundary >
  )
}

export default StickyHeaderContent